.dropdown{
    border: 1px solid #363636;
    margin-bottom: 10px;
}
.dropdown-value{
    padding: 0 42px 0 6px;
    color: #F2F2F2;	
    font-size: 22px;	
    line-height: 2.1;
    cursor: pointer;
    display: flex;  
    align-items: center;
    height: 48px;
    background: url('../../../../images/expand_more-grey.svg') no-repeat right 10px center / 20px auto;
}
.dropdown.active .dropdown-value{
    background: url('../../../../images/expand_less-grey.svg') no-repeat right 10px center / 20px auto;
}
.dropdown-options{
    display: none;  
}
.dropdown.active .dropdown-options{
    display: block;  
}
.dropdown-options-item{
    height: 48px;
    border-top: 1px solid #363636;
    padding: 0 12px;
    display: flex;  
    align-items: center;
    cursor: pointer;
}
.dropdown-options-item__title{
    font-family: 'Neue Machina UltraBold';
    color: #F2F2F2;	
    font-size: 22px;		
    line-height: 25px;
    margin-left: 10px;
}
.dropdown-options-item__subtitle{
    font-family: 'Neue Machina';
    font-weight: normal;
    color: #626262;	
    font-size: 12px;	
    line-height: 14px;
    margin-left: 10px;
}
.dropdown-options-item__price{
    color: #FFFFFF;	
    font-size: 16px;	
    font-weight: bold;	
    line-height: 21px;
    margin-right: 0;
    margin-left: auto;
}
.dropdown-options-item__radio{
    height: 14px;	
    width: 14px;	
    border-radius: 30px;
    border: 1px solid #979797;
    position: relative;
}
.dropdown-options-item.dropdown-options-item--active .dropdown-options-item__radio{
    border: 1px solid #ffffff;  
}
.dropdown-options-item.dropdown-options-item--active .dropdown-options-item__radio::after{
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 30px;
    width: 8px;
    height: 8px;
    background: #ffffff;
}