.upload-checkboxes{
    border-bottom: 1px solid #363636;  
}
.upload-checkbox{
    position: relative;
    border-top: 1px solid #363636;
    padding: 15px 20px 20px 30px;
}
.upload-checkbox a{
    color: #FF4343;
    text-decoration: none;
}
.upload-checkbox__radio{
    position: absolute;
    top: 14px;
    left: 0;
    height: 14px;	
    width: 14px;	
    border-radius: 30px;
    border: 1px solid #979797;
    margin-left: 5px;
}
.upload-checkbox__title{
    font-family: 'Neue Machina';
    font-size: 12px;	
    line-height: 14px;
    color: #FFFFFF;
    margin-right: 15px;
    margin-bottom: 7px;
    cursor: pointer;
}
.upload-checkbox__subtitle{
    font-family: 'Neue Machina';
    font-size: 12px;	
    line-height: 14px;
    color: #838383;
}
.upload-checkbox__help{
    height: 14px;	
    width: 14px;	
    font-size: 12px;	
    line-height: 14px;
    border-radius: 30px;
    border: 1px solid #ffffff;  
    position: absolute;
    text-align: center;
    top: 14px;
    right: 10px;
    cursor: pointer;
}
.upload-checkbox.upload-checkbox--active .upload-checkbox__radio{
    border: 1px solid #ffffff;  
}
.upload-checkbox.upload-checkbox--active .upload-checkbox__radio::after{
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 30px;
    width: 8px;
    height: 8px;
    background: #ffffff;
}
.upload-checkbox.upload-checkbox--error .upload-checkbox__radio{
    border: 1px solid #FF4343;  
}
.upload-checkbox.upload-checkbox--error .upload-checkbox__radio::after{
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 30px;
    width: 8px;
    height: 8px;
    background: #FF4343;
}
.upload-checkbox.upload-checkbox--error .upload-checkbox__title{
    color: #FF4343;
}
.upload-checkbox.upload-checkbox--error .upload-checkbox__help{
    color: #FF4343;
    border: 1px solid #FF4343; 
}
