@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');

@font-face {
  font-family: 'Neue Machina';
  src: url('./fonts/neuemachina-regular-webfont.woff2') format('woff2'),
       url('./fonts/neuemachina-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'Neue Machina UltraBold';
  src: url('./fonts/neuemachina-ultrabold-webfont.woff2') format('woff2'),
       url('./fonts/neuemachina-ultrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body{
  font-family: 'Roboto Mono', sans-serif;
  background-color: #0D0D0D;
  color: #ffffff;
}
a{
  color: #ffffff;
}
a:hover{
  opacity: 0.9;
}
.btn{
  font-size: 14px;	
  font-weight: 700;	
  letter-spacing: 0.54px;	
  line-height: 19px;
  text-decoration: none;
  padding: 12px 30px;
}
.btn-red{
  color: #ffffff;
  background: #FF4343;
}
.btn.disabled{
  cursor: default;
  color: #818181;
  background: #393939;
}
.container-center{
  text-align: center;
}
.container{
  max-width: 860px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
}
.container-big{
  max-width: 1124px; 
}
.content-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex: 1 1 100%;
}


/*
=========================================================
Header
=========================================================
*/
.header-section{
  padding-top: 35px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.header-logo{
  font-family: 'Neue Machina UltraBold';
  margin: 30px 0 0 40px;
  text-decoration: none;
  position: relative;
  z-index: 10;
}
.header-menu-section{
  display: none;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
}
.header-menu-section-wr{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
#burger {
  border-radius: 50px;
  width: 22px;
  height: 30px;
  position: absolute;
  top: 25px;
  right: 35px;
  margin: 0;
  z-index: 100;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
#burger span {
  position: relative;
  display: inline-block;
  text-align: left;
  width: 22px;
  height: 1px;
  border-radius: 3px;
  background: #fff;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
#burger span:before, #burger span:after {
  position: absolute;
  background: #fff;
  content: '';
  width: 22px;
  height: 1px;
  border-radius: 3px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
#burger span:before {
  top: -6px;
}
#burger span:after {
  top: 6px;
}
#burger.active span {
  background: transparent !important;
}
#burger.active span:before {
  -webkit-transform: rotate(45deg) translate(4px, 3px);
      -ms-transform: rotate(45deg) translate(4px, 3px);
          transform: rotate(45deg) translate(4px, 3px);
}
#burger.active span:after {
  -webkit-transform: rotate(-45deg) translate(6px, -4px);
      -ms-transform: rotate(-45deg) translate(6px, -4px);
          transform: rotate(-45deg) translate(6px, -4px);
}
.header-menu{
  padding: 120px 0 50px 0;
  margin: 0;
  list-style: none;
}
.header-menu__li{
  margin-bottom: 20px;
}
.header-menu__a{
  color: #FFFFFF;	
  font-family: 'Neue Machina UltraBold';
  font-size: 84px;	
  letter-spacing: 3.27px;	
  line-height: 97px;
  text-decoration: none;
}
.menu-active .header-menu-section{  
  background-color: #0D0D0D;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
}
.menu-active{
  overflow:hidden;
}
.header-menu-section__container{
  flex: 1 1 100%;
}
.contact-columns--header{
  margin-bottom: 70px;
}


/*
=========================================================
Footer
=========================================================
*/
.footer-section{
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 100px;
}
.footer-header{
  color: #FFFFFF;	
  font-family: 'Neue Machina UltraBold';
  font-size: 100px;	
  line-height: 139px;
  flex: 1 1 100%;
  margin-bottom: 50px;
}
.contact-columns{
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
}
.contact-col{
  flex: 1 1 33%;
}
.contact-col__h3{
  color: #FFFFFF;
  font-size: 18px;	
  font-weight: bold;	
  line-height: 24px;
  margin-bottom: 15px;
}
.contact-col__ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
.contact-col__ul li{
  margin-bottom: 8px;
}


/*
=========================================================
Frontpage
=========================================================
*/
.hero-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 150px;
  padding-bottom: 150px;
}
.hero-section__h1{
  color: #FFFFFF;	
  font-family: 'Neue Machina UltraBold';
  font-size: 54px;	
  letter-spacing: 2.1px;	
  line-height: 62px;
  margin-bottom: 25px;
  text-align: center;
}
.hero-section__text{
  color: #FFFFFF;
  font-size: 14px;	
  letter-spacing: 0.54px;	
  line-height: 19px;
  margin-bottom: 50px;
  flex: 1 1 100%;
  text-align: center;
}
.hero-section__btn-wr{
  flex: 1 1 100%;
  display: flex;
  justify-content: space-around;
}
.process-section{
  display: flex;
  justify-content: space-around;
  padding-bottom: 150px;
}
.process-section-container{
  flex-wrap: nowrap;
}
.process-item{
  flex: 1 1 30%;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}
.process-item__title{
  color: #FFFFFF;	
  font-family: 'Neue Machina UltraBold';
  font-size: 24px;	
  letter-spacing: 0.93px;
  line-height: 29px;
  margin-bottom: 20px;
}
.process-item__text{
  font-size: 14px;	
  letter-spacing: 0.54px;	
  line-height: 19px;
}
.benefits-section{
  display: flex;
  justify-content: space-around;
}
.benefit-item{
  margin-bottom: 150px;
}
.benefit-item__title{
  color: #FFFFFF;	
  font-family: 'Neue Machina UltraBold';
  font-size: 32px;	
  letter-spacing: 1.24px;	
  line-height: 37px;
  margin-bottom: 25px;
}
.benefit-item__text{
  color: #FFFFFF;	
  font-size: 14px;	
  letter-spacing: 0.54px;	
  line-height: 19px;
  margin-bottom: 50px;
}
.col-left{
  flex: 1 1 50%;
  margin-right: 60px;
}
.col-right{
  flex: 1 1 50%;
  margin-top: 150px;
  margin-left: 60px;
}
.benefits-section-container{
  flex-wrap: nowrap;
}
.benefit-item__image{
  background: #eeeeee;
  height: 510px;
}


/*
=========================================================
Shop
=========================================================
*/
.shop-section-columns{
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
}
.shop-section-left-col{
  flex: 0 0 330px;
  border-right: 1px solid #383838;
  padding: 120px 30px 100px 40px;
}
.shop-section-right-col{
  padding: 100px 30px 100px 40px;
  flex: 1 1 100%;
}
.shop-section-right-col--upload{
  justify-content: space-around;
  display: flex;
}
.footer-total-section{
  border-top: 1px solid #383838;
  background-color: #0D0D0D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 67px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer-total{
  margin-left: 40px;
  margin-right: auto;
}
.footer-total-btns{
  margin-right: 40px;
  margin-left: auto;
} 
.footer-total__title{
  color: #ffffff;
  font-size: 14px;	
  line-height: 19px;
  font-weight: bold;
  margin-bottom: 2px;
}
.footer-total__text{
  color: #626262;
  font-size: 14px;	
  line-height: 19px;
}
.shop-section-left-col__title{
  color: #F2F2F2;	
  font-family: 'Neue Machina UltraBold';
  font-size: 28px;	
  line-height: 32px;
  margin-bottom: 15px;
}
.shop-section-left-col__text{
  color: #F2F2F2;	
  font-size: 13px;	
  line-height: 24px;
  margin-bottom: 20px;
}
.poster-sample{
  background: #333333;  
  border-left: 1px solid #868686;
  border-bottom: 1px solid #868686;
  margin-bottom: 13px;
}
.poster-sample--a1{
  width: 415px;
  height: 590px;
  position: relative;
}
.poster-sample--a2{
  width: 295px;
  height: 415px;
  position: relative;
}
.poster-sample--a3{
  width: 180px;
  height: 235px;
  position: relative;
}
.poster-sample--a4{
  width: 125px;
  height: 175px;
  position: relative;
}
.poster-sample--a5{
  width: 90px;
  height: 125px;
  position: relative;
}
.poster-sample__title{
  font-family: 'Neue Machina UltraBold';
  position: absolute;
  right: 0;
  bottom: 0;
  color: #FFFFFF;	
  font-size: 67px;	
  font-weight: 800;	
  line-height: 67px;
}
.poster-sample__title--a4{
  font-size: 60px;	
  line-height: 60px;
}
.poster-sample__title--a5{
  font-size: 50px;	
  line-height: 50px;
}
.poster-sample__size{
  color: #F2F2F2;
  font-size: 13px;	
  line-height: 14px;
  padding-top: 10px;
  padding-left: 10px;
}
.poster-sample-cols{
  display: flex;
}
.poster-sample-col{
  margin-right: 16px;
}
.poster-sample-desc{
  color: #777777;
  font-size: 13px;	
  line-height: 18px;
  margin-bottom: 13px;
}
.poster-sample-desc__price{
  font-weight: 700;
  color: #ffffff;
  font-size: 13px;	
  line-height: 18px;
}


/*
=========================================================
Checkout
=========================================================
*/
.shop-total-table{
  margin-bottom: 40px;
}
.shop-total-table-row{
  display: flex;
  justify-content: space-between;
  color: #F2F2F2;	
  font-size: 13px;	
  line-height: 24px;
  margin-top: 8px;
}
.shop-total-table-row--total{
  font-family: 'Neue Machina UltraBold';
  font-size: 16px;	
  line-height: 24px;
  margin-top: 10px;
}
.place-order-btn{
  display: inline-block;
  cursor: pointer;
}
.shop-payment-methods{
  border-top: 1px solid #363636;
}
.shop-payment-method{
  padding: 15px 0 15px 40px;
  color: #FFFFFF;	
  font-family: "Neue Machina";	
  font-size: 12px;	
  line-height: 14px;
  border-bottom: 1px solid #363636;
  background: url('./images/chevron_right-grey.svg') no-repeat right 10px center / 20px auto,
              url('./images/credit_card-white.svg') no-repeat left 10px center / 20px auto;
}
.card-element{
  background: #ffffff;
}
.powered-by{
  margin-top: 10px;
  color: #6A6A6A;
  font-size: 11px;	
  line-height: 24px;
}


/*
=========================================================
Upload
=========================================================
*/
.dropzone{
  flex: 0 0 415px;
  width: 415px;
  height: 590px;
  background: #333333;
  display: flex;
  text-align: center;
  align-items: center;
  position: relative;
}
.dropzone__text{
  color: #F2F2F2;
  font-size: 14px;	
  line-height: 19px;
  flex: 1 1 100%;
}
.dropzone__preview{
  width: 415px;
  height: 590px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}